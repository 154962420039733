import React from "react";
import { Link } from "react-router-dom";

const BlogGridSection = () => {
  return (
    <>
      {/* Blog Grid Section one start */}
      <section className="blog-section blog-grid one">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/01_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        Professional Technology information &amp; Solutions...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/02_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        We offer AI consulting services and solutions...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/03_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        AI Services is a collection with prebuilt...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/04_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        The Security Risks Of Changing Owners...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/05_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        Avenga AI services help companies create AI...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/06_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        Use the same proven AI power AI capabilities...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/07_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        The Security Risks Of Changing Owners...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/08_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        Avenga AI services help companies create AI...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="blog-items "
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="img-file">
                  <img
                    className="img-fluid"
                    src="/assets/img/blog/09_blog.png"
                    alt=""
                  />
                </div>
                <div className="text-file">
                  <div className="intro">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="dp-img">
                        <img
                          src="/assets/img/testimonial/01_testimonial-profile.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <p className="admin">Admin</p>
                      </div>
                    </div>
                    <div>
                      <div className="date d-flex gap-2 align-items-center">
                        <span>
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                        <span>May 26, 2023</span>
                      </div>
                    </div>
                  </div>
                  <div className="title">
                    <h2>
                      <Link to="/blog-details">
                        Use the same proven AI power AI capabilities...
                      </Link>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, coned sectetur notte elit sed
                      do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="pagination"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fa-regular fa-chevrons-left" />
                    </Link>
                  </li>
                  <li>
                    <Link className="active" to="#">
                      1
                    </Link>
                  </li>
                  <li>
                    <Link to="#">2</Link>
                  </li>
                  <li>
                    <Link to="#">3</Link>
                  </li>
                  <li>
                    <Link to="#">4</Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa-regular fa-chevrons-right" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-image-file">
          <div className="shape-img-1 poa">
            <img src="/assets/img/icon/21_icon.png" alt="" />
          </div>
          <div className="shape-img-2 poa">
            <img src="/assets/img/icon/64_icon.png" alt="" />
          </div>
          <div className="shape-img-4 poa">
            <img src="/assets/img/icon/08_icon.png" alt="" />
          </div>
          <div className="shape-img-5 poa">
            <img src="/assets/img/icon/26_icon.png" alt="" />
          </div>
          <div className="shape-img-6 poa">
            <img src="/assets/img/icon/43_icon.png" alt="" />
          </div>
          <div className="shape-img-7 poa">
            <img src="/assets/img/icon/68_icon.png" alt="" />
          </div>
          <div className="shape-img-8 poa">
            <img src="/assets/img/icon/71_icon.png" alt="" />
          </div>
        </div>
      </section>
      {/* Blog Grid Section one end */}
    </>
  );
};

export default BlogGridSection;

import React from "react";
import { Link } from "react-router-dom";

const ServiceSectionAll = () => {
  return (
    <>
      {/* Service Section One Start */}
      <section
        className="service-section one single-row"
        data-background="/assets/img/shape/03_shape.svg"
      >
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/31_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Robotics &amp; Drones</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/32_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Analyze videos</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/33_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Acquire insights</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/34_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Extract text &amp; data</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/35_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Online experiences</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/40_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Control quality</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/85_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Facing Challenges</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/86_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Heavy Industry</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="section-wrapper">
                <div
                  className="wrapper-inner one "
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="img-file">
                    <img src="/assets/img/icon/87_icon.png" alt="" />
                  </div>
                  <div className="text-file">
                    <h3>
                      <Link to="#">Transportation</Link>
                    </h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Obcaecati iste inventore molestiae? Assumenda facilis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-image-file">
          <div className="shape-img-1 poa">
            <img src="/assets/img/icon/21_icon.png" alt="" />
          </div>
          <div className="shape-img-2 poa">
            <img src="/assets/img/icon/64_icon.png" alt="" />
          </div>
          <div className="shape-img-4 poa">
            <img src="/assets/img/icon/08_icon.png" alt="" />
          </div>
          <div className="shape-img-5 poa">
            <img src="/assets/img/icon/78_icon.png" alt="" />
          </div>
          <div className="shape-img-6 poa">
            <img src="/assets/img/icon/43_icon.png" alt="" />
          </div>
          <div className="shape-img-7 poa">
            <img src="/assets/img/icon/68_icon.png" alt="" />
          </div>
          <div className="shape-img-8 poa">
            <img src="/assets/img/icon/71_icon.png" alt="" />
          </div>
        </div>
      </section>
      {/* Service Section One End */}
    </>
  );
};

export default ServiceSectionAll;
